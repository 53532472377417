export const GAME_TITLE = 'Wordle CAT - Català'

export const WIN_MESSAGES = ['Bona feina!', 'Impressionant', 'Ben fet!']
export const GAME_COPIED_MESSAGE = 'Joc copiat al portapapers'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'No hi ha prou lletres'
export const WORD_NOT_FOUND_MESSAGE = 'Paraula no trobada'
export const HARD_MODE_ALERT_MESSAGE =
  "El mode difícil només es pot activar a l'inici!"
export const HARD_MODE_DESCRIPTION =
  'Qualsevol pista revelada s\'ha d\'utilitzar en les endevinalles posteriors'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Per millorar la visió del color'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `La paraula era ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Cal utilitzar ${guess} a la posició ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `La endevinalla ha de contenir ${letter}`
export const ENTER_TEXT = 'Enviar'
export const DELETE_TEXT = 'Esborrar'
export const STATISTICS_TITLE = 'Estadístiques'
export const GUESS_DISTRIBUTION_TEXT = 'Distribució de les endevinalles'
export const NEW_WORD_TEXT = 'Nova paraula en'
export const SHARE_TEXT = 'Compartir'
export const SHARE_FAILURE_TEXT =
  "No s'han pogut compartir els resultats. Aquesta funció només està disponible en contexts segurs (HTTPS), en alguns o tots els navegadors compatibles."
export const MIGRATE_BUTTON_TEXT = 'Transferir'
export const MIGRATE_DESCRIPTION_TEXT =
  'Feu clic aquí per transferir les vostres estadístiques a un dispositiu nou.'
export const TOTAL_TRIES_TEXT = 'Total intents'
export const SUCCESS_RATE_TEXT = 'Taxa d\'èxit'
export const CURRENT_STREAK_TEXT = 'Ratxa actual'
export const BEST_STREAK_TEXT = 'Millor ratxa'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Esteu utilitzant un navegador incrustat i podeu experimentar problemes per compartir o desar els vostres resultats. Us recomanem que utilitzeu el navegador per defecte del vostre dispositiu."

export const DATEPICKER_TITLE = 'Trieu una data passada'
export const DATEPICKER_CHOOSE_TEXT = 'Trieu'
export const DATEPICKER_TODAY_TEXT = 'avui'
export const ARCHIVE_GAMEDATE_TEXT = 'Data del joc'
